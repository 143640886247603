// extracted by mini-css-extract-plugin
export var interactive_container = "sketchbook2-module--interactive_container--hyKI7";
export var scrollbars = "sketchbook2-module--scrollbars--WqqTd";
export var vertical_scrollbar = "sketchbook2-module--vertical_scrollbar--JTQzJ";
export var horizontal_scrollbar = "sketchbook2-module--horizontal_scrollbar--xLG0F";
export var sketch = "sketchbook2-module--sketch--ZYDxu";
export var selected = "sketchbook2-module--selected--n+4zT";
export var large = "sketchbook2-module--large--HlFRo";
export var sketch_thumbnail = "sketchbook2-module--sketch_thumbnail--C3Yj-";
export var draggable = "sketchbook2-module--draggable--Zb0RY";
export var currently_dragging = "sketchbook2-module--currently_dragging--qprI4";
export var sticky = "sketchbook2-module--sticky--7jkHm";
export var sketchbook_controls = "sketchbook2-module--sketchbook_controls--2HpZ-";
export var detail_panel = "sketchbook2-module--detail_panel--Vcht6";
export var icon_toggle = "sketchbook2-module--icon_toggle--8Mqgt";
export var detail_content = "sketchbook2-module--detail_content--ucyXI";
export var image_wrapper = "sketchbook2-module--image_wrapper--+DUmd";
export var detail_title = "sketchbook2-module--detail_title--I+uJT";
export var mobile = "sketchbook2-module--mobile--b+eJL";
export var message = "sketchbook2-module--message--YiZj2";